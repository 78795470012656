<template>
  <div class="inputBoxWrap">
    <baseOnlyRuInput 
      v-if="isRuOnly"
      v-model="propValue" 
      :placeholder="placeholder"
      :class="{ errFieldBorder: errorMsg.length > 0 }"
      @blur="eventBlur()"
      @focus="eventFocus()"
      @keydown="selectSuggestion($event)" />
    <input 
      v-else
      type="text"
      v-model="propValue" 
      :placeholder="placeholder"
      :class="{ errFieldBorder: errorMsg.length > 0 }"
      @blur="eventBlur()"
      @focus="eventFocus()"
      @keydown="selectSuggestion($event)">
    <div class="suggestions" v-show="suggestions.length > 0">
      <p class="items" v-for="(item, index) in suggestions" :key="index" @click="onSearchItemSelected(item)"
        :class="{ hover: activeIndex === index }">
        {{ item.value }}
      </p>
    </div>
		<label>{{ label }}</label>
    <p class="errorMsg" v-if="errorMsg.length > 0">{{ errorMsg }}</p>
  </div>
</template>

<script>
import { getDadata } from '@/api/dadataApi'

export default {
  name: 'baseSelectDadata',
  components: {
    baseOnlyRuInput: () => import('@/components/baseOnlyRuInput')
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        [
          'LAST_NAME',
          'FIRST_NAME',
          'SECOND_NAME',
          'RESIDENTIAL_ADDRESS',
          'REGISTRATION_ADDRESS',
          'WORK_JOB',
          'PASSPORT_DEP'
        ].includes(value)
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      activeIndex: 0,
      suggestions: [],
    }
  },

  computed: {
    propValue: {
      get() {
        return this.value
      },
      set(value) {
        this.onInputChange(value)
        this.$emit('input', value)
      }
    },
    isRuOnly() {
      return [
        'LAST_NAME',
        'FIRST_NAME',
        'SECOND_NAME'
      ].includes(this.type)
    }
  },

  methods: {
    selectSuggestion(event) {
      switch (event.key) {
        case 'ArrowUp':
          if (this.activeIndex > 0) {
            this.activeIndex -= 1
          }
          break
        case 'ArrowDown':
          if (this.activeIndex < this.suggestions.length - 1) {
            this.activeIndex += 1
          }
          break
        case 'Enter':
          this.onSearchItemSelected(this.suggestions[this.activeIndex])
          break
        default:
          break
      }
    },
    eventBlur() {
      this.$emit('blur')
    	setTimeout(() => {
    		this.suggestions = []
    	}, 300)
    },
    eventFocus() {
      this.$emit('focus')
    },
    async onInputChange(query) {
      if (query.trim().length === 0) {
        return null
      }
      try {
        const { result, errorCode, errorMsg } = await getDadata(
          this.type,
          query
        )
        if (!!errorCode) {
          throw new Error(errorMsg)
        }
        this.suggestions = result?.suggestions ?? []
      } catch (e) {
        console.log(e)
      }
    },
    onSearchItemSelected(item) {
      this.$emit('dadata', item)
      this.$emit('input', item.value)
      this.suggestions = []
    },
  }
}
</script>

<style scoped>
.suggestions { position: absolute; left: 0; top: 10vw; width: 100%; z-index: 100; background: #ffffff; border: 1px solid #EEE; margin: 0; padding: 0 }
.items { border-bottom: 1px solid #eee; padding: 1vw }

@media (min-width: 760px) {
  .suggestions { top: 5vw }
  .items { padding: 0.5vw }
}

@media (min-width: 1240px) {
  .suggestions { top: 36px; }
  .items { padding: 5px }
  .items:hover,  .hover { background: #eee; cursor: pointer; }
}
</style>